<template>
  <div>
    <v-overlay :value="checkingCustomer">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import i18n from "../i18n.js";

export default {
  name: "CallbackMojeId",
  mounted() {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get("access_token");
    const userInfoEndpoint =
      process.env.VUE_APP_API_ROOT_MOJEID + "/oidc/userinfo/";

    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    axios
      .get(userInfoEndpoint, config)
      .then((response) => {
        let profile = response.data;
        //console.log(JSON.stringify(profile, null, 2));
        let street =
          !!profile.address && !!profile.address.street_address
            ? profile.address.street_address
            : "";
        let town =
          !!profile.address && !!profile.address.locality
            ? profile.address.locality
            : null;
        let zip =
          !!profile.address && !!profile.address.postal_code
            ? profile.address.postal_code
            : null;
        let phone = !!profile.phone_number
          ? profile.phone_number.replace(".", "")
          : null;

        const customer = {
          email: profile.email,
          firstName: profile.given_name,
          lastName: profile.family_name,
          birthDate: profile.birthdate,
          phone: phone,
          street: street,
          town: town,
          zip: zip,
        };

        this.setReadOnlyCheckout(true);

        this.checkCustomer({
          customer: customer,
          lang: i18n.locale,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    /*    
    let customerId = this.$router.currentRoute.query.readerNumber;
    if (typeof customerId === "undefined" || !customerId.length) {
      this.$router.push("/");
      return;
    }

    const customer = {
      couponOrCardNumber: customerId,
      email: this.$router.currentRoute.query.email,
      firstName: this.$router.currentRoute.query.firstName,
      lastName: this.$router.currentRoute.query.lastName,
    };

    this.checkCustomer({
      customer: customer,
      lang: this.$router.currentRoute.query.lang,
    });*/
  },
  computed: {
    ...mapGetters(["checkingCustomer"]),
  },
  methods: {
    ...mapActions(["checkCustomer", "setReadOnlyCheckout"]),
  },
};
</script>
