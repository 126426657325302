import i18n from '../i18n.js'

class ValidationRules {

    static getRules() {
        return {
            required: value => !!value || i18n.t('cart.requiredField'),
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || i18n.t('cart.invalidEmail')
            },
            zip: value => (!!value && value.length == 5) || i18n.t('cart.requiredZipLength'),
            phone: value => (!!value && value.length == 13) || i18n.t('cart.requiredPhoneLength'),
            file: value => !value || value.size < 2000000 || i18n.t('cart.requiredFileSize'),
            allow: value => { return true } // eslint-disable-line
        };
    }
}

export default ValidationRules;
