<template>
  <v-container>
    <v-row>
      <v-col class="col-md-12">
        <main-stepper> </main-stepper>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="col-md-12">
        <v-card>
          <v-card-text>
            <h1 class="mb-3">Smlouva o poskytování služeb</h1>
            <h2 class="mb-1">Rozsah služby</h2>
            <div v-for="n in 2" :key="n" class="mb-4">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                commodi earum tenetur. Asperiores dolorem placeat ab nobis iusto
                culpa, autem molestias molestiae quidem pariatur. Debitis beatae
                expedita nam facere perspiciatis. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Repellendus ducimus cupiditate
                rerum officiis consequuntur laborum doloremque quaerat ipsa
                voluptates, nobis nam quis nulla ullam at corporis, similique
                ratione quasi illo!
              </p>
            </div>
            <h2 class="mb-1">Odstoupení od smlouvy</h2>
            <div v-for="n in 2" :key="n * 5" class="mb-4">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi
                commodi earum tenetur. Asperiores dolorem placeat ab nobis iusto
                culpa, autem molestias molestiae quidem pariatur. Debitis beatae
                expedita nam facere perspiciatis. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Repellendus ducimus cupiditate
                rerum officiis consequuntur laborum doloremque quaerat ipsa
                voluptates, nobis nam quis nulla ullam at corporis, similique
                ratione quasi illo!
              </p>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <div class="text-center mb-3">
              <v-checkbox
                :label="$t('agreement.signCheckbox')"
                v-model="isAgreed"
              ></v-checkbox>
              <v-btn color="teal primary" :disabled="!isAgreed">{{
                $t("agreement.signButton")
              }}</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainStepper from "../components/Header/MainStepper";

export default {
  name: "Agreement",
  components: {
    MainStepper,
  },
  data() {
    return {
      activeLocale: "cs",
      isAgreed: false,
    };
  },
  mounted() {
    this.activeLocale = this.$i18n.locale;
  },
  watch: {
    "$i18n.locale": function (val) {
      this.activeLocale = val;
    },
  },
};
</script>
