import Vue from "vue";
import Vuex from "vuex";
import * as types from "./mutation-types";
import router from "../router";
//import VuexPersistence from "vuex-persist";
import i18n from "../i18n.js";
Vue.use(Vuex);

// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
//   reducer: (state) => ({
//     added: state.added,
//     user: state.user,
//     lang: state.lang,
//     readonlyCheckout: state.readonlyCheckout
//   }),
// });

const debug = process.env.NODE_ENV !== "production";

// initial state
const state = {
  stepNumber: 1,
  readonlyCheckout: false,
  checkingCustomer: false,
  loadingCategories: false,
  added: [],
  all: [],
  categories: [],
  currentCategory: {},
  currentProduct: {},
  user: {},
  orderId: null,
  paymentMethods: [],
  paymentMethodsLoaded: false,
  shippingMethods: [],
  shippingMethodsLoaded: false,
  snackbar: {
    active: false,
    text: "",
    type: "",
  },
  lang: i18n.locale,
  bankIDToken: null,
  mojeIDToken: null
};

// getters
const getters = {
  stepNumber: (state) => state.stepNumber,
  readonlyCheckout: (state) => state.readonlyCheckout,
  shippingMethodsLoaded: (state) => state.shippingMethodsLoaded,
  paymentMethodsLoaded: (state) => state.paymentMethodsLoaded,
  paymentMethods: (state) => state.paymentMethods,
  shippingMethods: (state) => state.shippingMethods,
  user: (state) => state.user,
  orderId: (state) => state.orderId,
  snackbar: (state) => state.snackbar,
  checkingCustomer: (state) => state.checkingCustomer,
  loadingCategories: (state) => state.loadingCategories,
  currentCategory: (state) => state.currentCategory,
  currentProduct: (state) => state.currentProduct,
  allProducts: (state) => {
    if (state.currentCategory) {
      const category = state.categories.find(
        (c) => c.alias === state.currentCategory.alias
      );
      if (category) {
        return state.all.filter(
          (item) => item.productCategoryId === category.id
        );
      }
    }
    return state.all;
  }, // would need action/mutation if data fetched async
  allCategories: (state) => state.categories,
  cartProducts: (state) => {
    return state.added.map(({ productId, qty }) => {
      const product = state.all.find((p) => p.id === productId);

      if (!product) return null;

      return {
        productId: product.id,
        name: product.name,
        amountWithVat: product.amountWithVat,
        imageUrl: product.imageUrl,
        qty,
      };
    });
  },
  bankIDToken: (state) => state.bankIDToken,
  bankIdLoginURL: () => {
    const authEndpoint = process.env.VUE_APP_API_ROOT_BANKID;
    const scopes = ['openid', 'profile.addresses', 'profile.birthdate', 'profile.email', 'profile.name', 'profile.phonenumber'];

    const authUriParams = {
      client_id: process.env.VUE_APP_BANKID_CLIENTID,
      state: '',
      scope: scopes.join(' '),
      redirect_uri: window.location.origin + '/callback-bankid',
      response_type: 'token',
      nonce: process.env.VUE_APP_BANKID_NONCE,
      prompt: 'login',
      display: 'page',
      acr_values: 'loa3'
    };
    const uriParams = new URLSearchParams(authUriParams);
    return `${authEndpoint}/auth?${uriParams}`;
  },
  mojeIDToken: (state) => state.mojeIDToken,
  mojeIdLoginURL: () => {
    const authEndpoint = process.env.VUE_APP_API_ROOT_MOJEID + "/oidc/authorization/";
    const scopes = ['openid'];
    const claims = { "userinfo": { "family_name": null, "given_name": null, "email": null, "birthdate": null, "phone_number": null, "address": null } };

    function generateNonce() {
      const chars =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
      let randomString = "n-";
      for (let i = 0; i < 16; i++) {
        const num = Math.floor(Math.random() * chars.length);
        randomString += chars[num];
      }
      return randomString;
    };

    const authUriParams = {
      client_id: process.env.VUE_APP_MOJEID_CLIENTID,
      state: '',
      scope: scopes.join(' '),
      claims: JSON.stringify(claims),
      redirect_uri: window.location.origin + '/callback-mojeId',
      response_type: 'token',
      nonce: generateNonce()

    };
    const uriParams = new URLSearchParams(authUriParams);
    return `${authEndpoint}?${uriParams}`;
  }
};

// actions
const actions = {
  handleFormSubmit() {
    return false;
  },
  hideSnackbar({ commit }) {
    commit(types.HIDE_SNACKBAR);
  },
  showSuccessSnackbar({ commit }, message) {
    commit(types.HIDE_SNACKBAR);
    commit(types.SHOW_SNACKBAR, { message });
    setTimeout(function () {
      commit(types.HIDE_SNACKBAR);
    }, 4000);
  },
  setOrderId({ commit }, orderId) {
    commit(types.SET_ORDER_ID, { orderId: orderId });
  },
  emptyCart({ commit }) {
    commit(types.EMPTY_CART);
  },
  changeQuantity({ commit }, { product: product, qty: qty }) {
    if (qty === 0) {
      commit(types.REMOVE_FROM_CART, { productId: product.id });
      return;
    }
    commit(types.CHANGE_QUANTITY, { product: product, qty: qty });
  },
  retrieveShippingMethods({ commit }, lang) {
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
        process.env.VUE_APP_API_CART +
        "/" +
        process.env.VUE_APP_API_ESHOP_ID +
        "/shippingmethod/items?langCode=" +
        lang.toUpperCase()
      )
      .then((response) => {
        commit(types.RETRIEVE_SHIPPING_METHODS, { response: response.data });
        commit(types.SHIPPING_METHODS_LOADED);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  retrievePaymentMethods({ commit }, lang) {
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
        process.env.VUE_APP_API_CART +
        "/" +
        process.env.VUE_APP_API_ESHOP_ID +
        "/paymentmethod/items?langCode=" +
        lang.toUpperCase()
      )
      .then((response) => {
        commit(types.RETRIEVE_PAYMENT_METHODS, { response: response.data });
        commit(types.PAYMENT_METHODS_LOADED);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addToCart({ commit }, { product: product, qty: qty }) {
    commit(types.ADD_TO_CART, {
      productId: product.id,
      qty: qty,
    });
    if (Vue.gtm.enabled()) {
      Vue.gtm.trackEvent({
        event: "addToCart",
        category: "Ecomerce",
        ecommerce: {
          add: {
            products: [
              {
                id: product.id,
                name: product.name,
                category: product.productCategoryName,
                quantity: qty,
              },
            ],
          },
        },
      });
    }
    // dispatch('showSuccessSnackbar', i18n.t('cart.productAdded', {product: product.name}))
  },
  removeFromCart({ commit }, product) {
    commit(types.REMOVE_FROM_CART, {
      productId: product.productId,
    });
    if (Vue.gtm.enabled()) {
      Vue.gtm.trackEvent({
        event: "removeFromCart",
        category: "Ecomerce",
        ecommerce: {
          remove: {
            products: [
              {
                id: product.productId,
                name: product.name,
                category: product.productCategoryName,
                quantity: 1,
              },
            ],
          },
        },
      });
    }
  },
  retrieveProducts({ commit, dispatch, state }, response) {
    commit(types.RETRIEVE_CATEGORIES, { response: response.data });
    dispatch("changeCategory");
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
        process.env.VUE_APP_API_PROJECT +
        process.env.VUE_APP_API_PROJECT_KEY +
        "/products?langCode=" +
        state.lang.toUpperCase()
      )
      .then((response) => {
        commit(types.RETRIEVE_PRODUCTS, { response: response.data });
        dispatch("changeProduct");
        commit(types.LOADING_CATEGORIES);
      })
      .catch((error) => {
        this.onError(error);
      });
  },
  userRefreshCart({ commit }, customer) {
    commit(types.USER_REFRESH_CART, customer);
  },
  retrieveProductsAndAddToCart({ commit, state }) {
    let isCheckout = router.currentRoute.name === "Checkout";

    if (!state.all.length) {
      window.axios
        .get(
          process.env.VUE_APP_ROOT_API +
          process.env.VUE_APP_API_PROJECT +
          process.env.VUE_APP_API_PROJECT_KEY +
          "/products"
        )
        .then((response) => {
          commit(types.USER_ADD_TO_CART, {
            response: response.data,
          });
          commit(types.CHECKING_USER);
          if (!isCheckout)
            router.push("/checkout");
        })
        .catch((error) => {
          this.onError(error);
        });
    } else {
      commit(types.USER_ADD_TO_CART, {
        response: state.all,
      });
      commit(types.CHECKING_USER);
      if (!isCheckout)
        router.push("/checkout");
    }
  },
  retrieveCategories({ dispatch, commit }, lang) {
    commit(types.LOADING_CATEGORIES);
    commit(types.SET_LANG, { lang });
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
        process.env.VUE_APP_API_PROJECT +
        process.env.VUE_APP_API_PROJECT_KEY +
        "/productcategories?langCode=" +
        lang.toUpperCase()
      )
      .then((response) => {
        dispatch("retrieveProducts", response);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  changeCategory({ commit }, categoryId = null) {
    commit(types.CHANGE_CATEGORY, { categoryId: categoryId });
  },
  changeProduct({ commit }) {
    commit(types.CHANGE_PRODUCT);
  },
  checkCustomer({ commit, dispatch }, { customer: customer, lang: lang }) {
    commit(types.CHECKING_USER);
    commit(types.SET_USER, { customer });
    commit(types.SET_LANG, { lang });
    dispatch("retrieveProductsAndAddToCart");
  },
  getPaymentFeedback({ commit }, paymentData) {
    commit(types.CHECKING_USER);
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
        "/api/payment/feedback/" +
        process.env.VUE_APP_API_ESHOP_ID +
        paymentData
      )
      .then((response) => {
        commit(types.SET_ORDER_ID, { orderId: response.data });
        commit(types.CHECKING_USER);
        router.push("/checkout/success");
      })
      .catch(() => {
        // eslint-disable-line
        commit(types.CHECKING_USER);
        router.push("/");
      });
  },
  checkCustomerCard({ commit }, { coupon: customerCard, email: email }) {
    commit(types.CHECKING_USER);
    window.axios
      .get(
        process.env.VUE_APP_ROOT_API +
        process.env.VUE_APP_API_CART +
        "/" +
        process.env.VUE_APP_API_ESHOP_ID +
        "/customer?cardNumber=" +
        customerCard +
        "&email=" +
        email
      )
      .then((response) => {
        commit(types.RETRIEVE_USER, { response: response.data });
      })
      .catch(() => {
        // eslint-disable-line
        commit(types.CHECKING_USER);
      });
  },
  setLang({ commit }, lang) {
    commit(types.SET_LANG, { lang });
  },
  setStep({ commit }, step) {
    commit(types.SET_STEP, { step });
  },
  setReadOnlyCheckout({ commit }, isReadonly) {
    commit(types.SET_READONLY_CHECKOUT, { isReadonly });
  }
};

// mutations
const mutations = {
  [types.SET_LANG](state, { lang }) {
    state.lang = lang;
  },
  [types.CHECKING_USER](state) {
    state.checkingCustomer = !state.checkingCustomer;
  },

  [types.USER_ADD_TO_CART](state, { response }) {
    state.all = response;
    let user = state.user;

    this.commit(types.USER_REFRESH_CART, user);
  },

  [types.USER_REFRESH_CART](state, customer) {
    var productId = null;
    let ageInYears = window.getAgeInYears(customer.birthDate);

    if (customer.disabledPerson)
      productId = process.env.VUE_APP_API_PROD_DISABLED;
    else if (ageInYears >= 70)
      productId = process.env.VUE_APP_API_PROD_PENSIONER;
    else
      productId = process.env.VUE_APP_API_PROD_STD;

    state.added = [];
    this.commit(types.ADD_TO_CART, {
      productId: productId,
      qty: 1,
    });
  },

  [types.SHOW_SNACKBAR](state, { message }) {
    state.snackbar.active = true;
    state.snackbar.message = message;
    state.snackbar.type = "success";
  },

  [types.EMPTY_CART](state) {
    state.added = [];
  },

  [types.HIDE_SNACKBAR](state) {
    state.snackbar.active = false;
  },

  [types.SET_ORDER_ID](state, { orderId }) {
    state.orderId = orderId;
  },

  [types.ADD_TO_CART](state, { productId, qty }) {
    const record = state.added.find((p) => p.productId === productId);

    if (!record) {
      state.added.push({
        productId,
        qty: qty,
      });
    } else {
      state.added = [];
    }
  },

  [types.REMOVE_FROM_CART](state, { productId }) {
    const record = state.added.find((p) => p.productId === productId);

    if (record) {
      state.added = state.added.filter(function (item) {
        return item.productId !== productId;
      });
    }
  },

  [types.CHANGE_QUANTITY](state, { product, qty }) {
    const record = state.added.find((p) => p.productId === product.id);

    if (record) {
      record.qty = qty;
    }
  },

  [types.RETRIEVE_SHIPPING_METHODS](state, { response: methods }) {
    state.shippingMethods = methods;
  },

  [types.SHIPPING_METHODS_LOADED](state) {
    state.shippingMethodsLoaded = true;
  },

  [types.RETRIEVE_PAYMENT_METHODS](state, { response: methods }) {
    state.paymentMethods = methods;
  },

  [types.PAYMENT_METHODS_LOADED](state) {
    state.paymentMethodsLoaded = true;
  },

  [types.RETRIEVE_PRODUCTS](state, { response: products }) {
    state.all = products;
  },

  [types.RETRIEVE_CATEGORIES](state, { response: categories }) {
    state.categories = categories;
  },

  [types.CHANGE_CATEGORY](state, { categoryId: categoryId }) {
    if (categoryId) {
      state.currentCategory = state.categories.find((c) => c.id === categoryId);
      return;
    }

    if (router.currentRoute.name === "Homepage") {
      state.currentCategory = state.categories.find(
        (c) => c.id === process.env.VUE_APP_MAIN_CATEGORY
      );
      return;
    }

    let categoryAlias = router.currentRoute.params;
    if (typeof categoryAlias.alias !== "undefined") {
      state.currentCategory = state.categories.find(
        (c) => c.alias === categoryAlias.alias
      );
    }
  },

  [types.CHANGE_PRODUCT](state) {
    let productAlias = router.currentRoute.params;
    if (typeof productAlias.alias !== "undefined") {
      state.currentProduct = state.all.find(
        (c) => c.alias === productAlias.alias
      );
    }
  },

  [types.LOADING_CATEGORIES](state) {
    state.loadingCategories = !state.loadingCategories;
  },

  [types.RETRIEVE_USER](state, { response: user }) {
    state.user = user;
  },

  [types.SET_USER](state, { customer }) {
    state.user = customer;
  },

  [types.SET_STEP](state, { step }) {
    state.stepNumber = step;
  },

  [types.SET_READONLY_CHECKOUT](state, { isReadonly }) {
    state.readonlyCheckout = isReadonly;
  },

};

export default new Vuex.Store({
  state,
  strict: debug,
  getters,
  actions,
  mutations,
  //  plugins: [vuexLocal.plugin],
});
