<template>
  <v-container fluid grid-list-lg class="cart pa-0">
    <v-container fluid>
      <v-row class="grey lighten-3 white--text">
        <v-col cols="12" class="text-center">
          <h2
            class="font-weight-light text-uppercase py-2 mb-0 black--text"
            :class="{ 'text-h5': $vuetify.breakpoint.smAndUp }"
          >
            {{ $t("category.subscriptions") }}
          </h2>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="checkout mt-3">
      <v-row>
        <v-col class="col-md-12">
          <main-stepper> </main-stepper>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="checkout mt-3">
      <v-row>        
        <v-col class="col-md-6">
          <v-card class="mx-auto d-flex flex-column" height="100%" min-width="400">
            <v-card-title class="justify-center text-center">
              {{ $t("cart.bankIdHeader") }}
            </v-card-title>
            <div class="text-center">
              <img height="40px" :src="require('@/assets/bankid_logo.png')" />
            </div>
            <v-card-text class="text-center flex-grow-1">
              <div v-html="$t('cart.bankIdDescription')"></div>
              <div>
                <v-btn
                  text
                  color="teal accent-4"
                  href="https://www.bankid.cz"
                  target="_blank"
                  >{{ $t("cart.moreInformation") }}</v-btn
                >
              </div>
            </v-card-text>

            <v-card-actions class="justify-center mb-2">
              <v-btn text color="teal accent-4" :href="bankIdLoginURL">
                <img height="35px" :src="require(`@/assets/${$t('cart.bankIdButtonImage')}`)" />                
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

<!--         <v-col class="col-md-4">
          <v-card class="mx-auto d-flex flex-column" width="371" height="100%">
            <v-card-title class="justify-center text-center"
              >{{ $t("cart.mojeIdHeader") }}
            </v-card-title>
            <div class="text-center">
              <img height="40px" :src="require('@/assets/mojeid_logo.png')" />
            </div>
            <v-card-text class="text-center flex-grow-1">
              <div v-html="$t('cart.mojeIdDescription')"></div>
              <div>
                <v-btn
                  text
                  color="teal accent-4"
                  href="https://www.mojeid.cz"
                  target="_blank"
                  >{{ $t("cart.moreInformation") }}</v-btn
                >
              </div>
            </v-card-text>

            <v-card-actions class="justify-center mb-2">
              <v-btn text color="teal accent-4" :href="mojeIdLoginURL">
                <img src="../assets/mojeid_button.png" />
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
 -->        
        <v-col class="col-md-6">
          <v-card class="mx-auto d-flex flex-column" height="100%" min-width="400">
            <v-card-title class="justify-center text-center"
              >{{ $t("cart.registrationHeader") }}
            </v-card-title>
            <div class="text-center">
              <img
                height="40px"
                :src="require('@/assets/czechpoint_logo.gif')"
              />
            </div>
            <v-card-text class="text-center flex-grow-1">
              <div v-html="$t('cart.registrationDescription')"></div>
              <div>
                <v-btn
                  text
                  color="teal accent-4"
                  :href="$t('cart.registrationButtonMoreInfo')"
                  target="_blank"
                  >{{ $t("cart.moreInformation") }}</v-btn
                >
              </div>
            </v-card-text>

            <v-card-actions class="justify-center mb-2">
              <v-btn color="teal primary" :href="$t('cart.registrationButtonUrl')" target="_blank">
                {{ $t("cart.registrationButton") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>        
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getProductImage, formatThousands } from "../Helper";
import MainStepper from "../components/Header/MainStepper";
import mojeId from "@cz-nic/mojeid-connect";

export default {
  name: "Cart",
  mixins: [getProductImage, formatThousands],
  components: {
    MainStepper,
  },
  mounted() {
    this.setStep(1);
  },
  computed: {
    ...mapGetters([
      "allProducts",
      "cartProducts",
      "user",
      "bankIdLoginURL",
      "mojeIdLoginURL",
    ]),

    itemsInCart() {
      let cart = this.cartProducts;
      if (!cart[0]) return null;
      return cart.reduce((accum, item) => accum + item.qty, 0);
    },
    total() {
      if (this.cartProducts[0] === null) return null;
      return this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);
    },
    totalDiscount() {
      if (this.cartProducts[0] === null) return null;
      let total = this.cartProducts.reduce((total, p) => {
        return total + p.amountWithVat * p.qty;
      }, 0);

      if (this.discount) {
        return parseInt(total) - this.discount;
      }

      return total;
    },
    discount() {
      if (this.user && this.user.discount) {
        return Math.round(this.total * this.user.discount);
      }

      return false;
    },
  },
  methods: {
    ...mapActions([
      "changeQuantity",
      "removeFromCart",
      "setStep",
      "setReadOnlyCheckout",
    ]),
    registration() {
      this.setReadOnlyCheckout(false);
      this.$router.push("/checkout");
    },
    onMojeIdClick() {
      window.mojeId.requestAuthentication();
    },
    getPrice(price, quantity) {
      return quantity * price;
    },
    changeValue(product) {
      this.changeQuantity(product);
    },
  },
};
</script>

<style lang="scss">
.cart {
  .mobile-table {
    font-size: 14px;
  }
  .item-price {
    font-size: 16px;
  }
  .order-total {
    font-size: 18px;
    .amount {
      font-size: 22px;
    }
  }
  .vue-numeric-input {
    input {
      outline: none;
      height: 100%;
    }
    .btn {
      background: transparent;
      box-shadow: none;
      border: none;
      &:hover {
        background: #fa3600;
      }
    }
  }
  .empty-cart {
    font-size: 86px !important;
    color: rgba(135, 135, 135, 0.15) !important;
  }
}
</style>

<style lang="scss">
.v-list-item__title {
  white-space: unset;
}
</style>
