<template>
  <div>
    <v-overlay :value="checkingCustomer">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2 v-if="!!callbackBankIdError" v-html=" $t('error.bankIdError')"></h2>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import i18n from "../i18n.js";

export default {
  name: "CallbackBankId",
  data() {
    return {
      callbackBankIdError: false
    }
  },
  mounted() {        
    this.callbackBankIdError = false;
    const urlParams = new URLSearchParams(window.location.search);
    let error = urlParams.get('error');
    if (error) {
      this.callbackBankIdError = true;
      return;
    }

    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get("access_token");

    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    axios
      .get(process.env.VUE_APP_API_ROOT_BANKID + "/profile", config)
      .then((response) => {        
        let profile = response.data;  
        
        debugger;
        if (!profile.addresses || !profile.birthdate) {
          this.callbackBankIdError = true;
          return;
        }

        let primaryAddress = profile.addresses.find(a => a.type === 'PERMANENT_RESIDENCE');        
        //console.log(JSON.stringify(profile, null, 2));
        let street =
          !!primaryAddress && !!primaryAddress.street
            ? primaryAddress.street
            : "";
        
        let buildingapartment =
          !!primaryAddress && !!primaryAddress.buildingapartment
            ? primaryAddress.buildingapartment
            : "";

        let streetnumber =
          !!primaryAddress && !!primaryAddress.streetnumber
            ? primaryAddress.streetnumber
            : "";

        let nbr = buildingapartment + (!!buildingapartment && !!streetnumber ? "/" :"") + streetnumber;
          street += !!nbr ? " " + nbr : "";

        let town =
          !!primaryAddress && !!primaryAddress.city
            ? primaryAddress.city
            : null;
        let zip =
          !!primaryAddress && !!primaryAddress.zipcode
            ? primaryAddress.zipcode
            : null;

        let country = !!primaryAddress && !!primaryAddress.country
          ? primaryAddress.country
          : null;

        let ruian = !!primaryAddress && !!primaryAddress.ruian_reference
          ? primaryAddress.ruian_reference
          : null;
        
        const customer = {
          email: profile.email,
          firstName: profile.given_name,
          lastName: profile.family_name,
          birthDate: profile.birthdate,
          phone: profile.phone_number ? profile.phone_number : null,
          street: street,
          town: town,
          zip: zip,
          country: country,
          ruian: ruian,
          bankIDOid: profile.sub,
          disabledPerson: false
        };

        this.setReadOnlyCheckout(true);

        this.checkCustomer({
          customer: customer,
          lang: i18n.locale,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    ...mapGetters(["checkingCustomer"]),
  },
  methods: {
    ...mapActions(["checkCustomer", "setReadOnlyCheckout"]),
  },
};
</script>
