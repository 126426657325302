<template>
  <div id="app">
    <v-app>
       <v-alert
       v-if ="!!notification"
      border="bottom"
      color="red"
      dark
      class="text-center"      
    >
     <span class="alert" v-html="notification"></span>    
    </v-alert>
      <nav-bar :isCheckout="isCheckout"></nav-bar>
      <div class="top-bar primary"></div>
      <v-main>
        <router-view></router-view>
      </v-main>
      <app-footer :isCheckout="isCheckout" />
      <snackbar />
      <cookie />
    </v-app>
  </div>
</template>

<script>
import Cookie from "./components/System/Cookie.vue";
import NavBar from "./components/System/NavBar.vue";
import Footer from "./components/Footer/Footer";
import Snackbar from "./components/System/Snackbar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "app",
  // metaInfo: {
  //   title: this.$t("cart.checkout"),
  //   titleTemplate: "%s | Národní knihovna",
  // },
  metaInfo() {
    return {
      title: this.$t("homepage.title") || "Default",
      titleTemplate: "%s",
    };
  },
  components: {
    NavBar,
    AppFooter: Footer,
    Snackbar,
    Cookie,
  },
  computed: {
    ...mapGetters(["allCategories"]),
       notification() {
         
                if (this.allCategories[0]) {
                    return this.allCategories[0].description;
                }

                return null;
            }

  },
  data() {
    return {
      isCheckout: false,
    };
  },
  mounted() {
    this.isCheckout = this.$router.currentRoute.name === "Checkout";
    if (!this.allCategories.length) {
      this.retrieveCategories(this.$i18n.locale);
    }
  },
  watch: {
    "$route.name": function (val) {
      this.isCheckout = val === "Checkout";
    },
    "$i18n.locale": function (val) {
      this.retrieveCategories(val);
    },
  },
  methods: {
    ...mapActions(["retrieveCategories"]),
  },
};
</script>

<style lang="scss">
@media (min-width: 1904px) {
  .container {
    max-width: 1185px;
    &.container--fluid {
      max-width: 100%;
    }
  }
}

.top-bar {
  height: 2px;
}

.v-application {
  font-family: "Open Sans", sans-serif !important;
  .body-2 {
    font-family: "Open Sans", sans-serif !important;
  }
}
.alert p
{
  margin-bottom: 0;
}
</style>
