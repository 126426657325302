<template>
  <v-card flat>
    <v-card-title class="pb-0">
      <h3 class="checkout-section-title">{{ $t("checkout.deliveryTitle") }}</h3>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-if="false"
            v-model="form.customer.externalId"
            :label="$t('checkout.readerNumber')"
            required
            :rules="[rules.required]"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field 
            v-model="form.customer.firstName"
            :label="$t('checkout.firstName')"
            required
            :rules="[rules.required]"
            :readonly="readonlyCheckout && !testMode"
            maxlength="50"
          ></v-text-field>          
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field 
            v-model="form.customer.lastName"
            :label="$t('checkout.lastName')"
            required
            :rules="[rules.required]"
            :readonly="readonlyCheckout && !testMode"
            maxlength="50"
          ></v-text-field>          
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            @blur="blur"
            v-model="form.customer.email"
            :label="$t('checkout.email')"
            :rules="[rules.required, rules.email]"
            :error-messages="error"
            :loading="loading"
            :error="error !== null"
            :append-icon="success ? 'mdi-check' : ''"
            prepend-icon="mdi-email"
            :readonly="readonlyCheckout && !!form.customer.email && !testMode"            
            maxlength="50"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDateFormatted"
                :label="$t('checkout.birthDate')"
                prepend-icon="mdi-calendar"
                readonly
                required
                :rules="[rules.required]"
                v-bind="attrs"
                v-on="on"               
                persistent-hint
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="birthDate"
              :active-picker.sync="activePicker"
              :locale="this.$i18n.locale"
              :reactive="false"
              :max="maxBirthDate"
              :min="minBirthDate"
              @change="onBirthdateChange"
              :readonly="readonlyCheckout && !!form.customer.birthDate && !testMode"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.customer.phone"
            v-mask="mask"
            required
            :rules="[rules.required, rules.phone]"
            prepend-icon="mdi-phone"
            :label="$t('checkout.phone')"                        
             :hint="$t('checkout.phoneHint')"
             persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2>{{ $t("checkout.address1Title") }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="form.customer.address.street"
            :label="$t('checkout.street')"
            required
            :rules="[rules.required]"
            :readonly="readonlyCheckout && !!form.customer.address.street"
            maxlength="250"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.customer.address.town"
            :label="$t('checkout.city')"
            required
            :rules="[rules.required]"
            :readonly="readonlyCheckout && !!form.customer.address.town"
            maxlength="200"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.customer.address.zip"
            v-mask="maskZip"
            :label="$t('checkout.zip')"
            required            
            :rules="[rules.required, rules.zip]"
            :readonly="readonlyCheckout && !!form.customer.address.zip"
            maxlength="5"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <h2>{{ $t("checkout.address2Title") }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <v-checkbox
            class="mt-0"
            v-model="address2Disable"
            :label="$t('checkout.address2Disable')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row v-if="!address2Disable">
        <v-col cols="12" md="12">
          <v-text-field
            v-model="form.customer.address2.street"
            :label="$t('checkout.street2')"
            required
            :rules="[rules.required]"
            :hint="$t('checkout.streetHint')"
            persistent-hint
            maxlength="250"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!address2Disable">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.customer.address2.town"
            :label="$t('checkout.city2')"
            required
            :rules="[rules.required]"
            maxlength="200"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="form.customer.address2.zip"
            v-mask="maskZip"
            :label="$t('checkout.zip2')"
            required
            :rules="[rules.required, rules.zip]"
            maxlength="5"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="disabledPersonVisible">
        <v-col cols="12" md="12">
            <h2>{{ $t("checkout.ztpTitle") }}</h2>
        </v-col>
      </v-row>
      <v-row v-if="disabledPersonVisible">
        <v-col cols="12" md="12">
          <v-checkbox
            class="mt-0"            
            :label="$t('checkout.ztp')"    
            v-model="disabledPerson"                    
          ></v-checkbox>
          <v-file-input    
            v-if="disabledPerson"
            small-chips             
            :label="$t('checkout.file')"            
            @change="onFileChange"            
            :rules="[rules.required, rules.file]"
          ></v-file-input>
        </v-col>
      </v-row>

    </v-card-text>
    <v-overlay :value="checkingCustomer">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mask } from 'vue-the-mask'
import i18n from "../../i18n.js";

export default {
  name: "DeliveryAddress",
  directives: {
      mask,
  },
  props: ["form", "refs"],
  data() {
    return {
      mask: '+420#########',
      maskZip:'#####',
      activePicker: null,
      menu: false,
      validEmail: null,
      address2Disable: false,
      disabledPerson: false,      
      disabledPersonVisible: false,
      loading: false,
      rules: window.validationRules.getRules(),
      error: null,
      success: false,
      birthDate: null,      
    };
  },
  mounted() {
    //this.birthDate = new Date().toISOString().slice(0, 10);  
    this.birthDate = this.form.customer.birthDate;
  },
  computed: {
    ...mapGetters(["checkingCustomer", "user", "readonlyCheckout"]),
    maxBirthDate() {
      let date = new Date();
      date.setFullYear(date.getFullYear() - 15);
      return date.toISOString().slice(0, 10);
    },
    minBirthDate() {
      let date = new Date();
      date.setFullYear(date.getFullYear() - 100);
      return date.toISOString().slice(0, 10);
    },
    birthDateFormatted() {
      return this.formatDate(this.birthDate);
    },
    testMode() {    
      return process.env.VUE_APP_TEST_MODE=="true";
    }
  },
  watch: {
    birthDate(val) {      
      this.form.customer.birthDate = new Date(val).toISOString();
      this.dateFormatted = this.formatDate(val);
      let ageInYears = window.getAgeInYears(val);
      this.disabledPersonVisible = ageInYears < 70;
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    disabledPerson(value){       
      this.form.customer.disabledPerson = value;
      this.userRefreshCart(this.form.customer);       
    },
    address2Disable(value) {
      if (value) {
        this.form.customer.address2.street = this.form.customer.address.street;
        this.form.customer.address2.town = this.form.customer.address.town;
        this.form.customer.address2.zip = this.form.customer.address.zip;
        //this.form.customer.address2.ruian = this.form.customer.address.ruian;   
      }
      else {
        this.form.customer.address2.street = null;
        this.form.customer.address2.town = null;
        this.form.customer.address2.zip = null;
        //this.form.customer.address2.ruian = null;
      }
    }
  },
  methods: {
    ...mapActions(["checkCustomerCard", "userRefreshCart"]),
    onBirthdateChange(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getCustomerByCard() {
      if (this.form.customer.couponCode && this.form.customer.email) {
        this.checkCustomerCard({
          coupon: this.form.customer.couponCode,
          email: this.form.customer.email,
        });
      }
    },
    blur() {
      if (
        this.validEmail !== this.form.customer.email &&
        this.refs.checkoutForm.validate()
      ) {
        this.loading = true;
        this.error = null;
        this.$emit("validateEmail");
        window.axios
          .get(
            process.env.VUE_APP_ROOT_API +
              process.env.VUE_APP_API_ROOT +
              "/email/validate/" +
              this.form.customer.email,
            this.form
          )
          .then((response) => {
            this.onValid(response);
          })
          .catch((error) => {
            this.onValidError(error);
          });
      }
    },
    onValid(response) {
      if (response.data.isValid) {
        this.error = null;
        this.success = true;
      } else {
        this.error = "Toto není validní emailová adresa";
      }
      this.loading = false;
      this.validEmail = this.form.customer.email;
      this.$emit("validateEmail");
    },
    // eslint-disable-next-line
    onValidError(error) {
      this.loading = false;
      this.error = "Toto není validní emailová adresa";
      this.$emit("validateEmail");
    },
    onFileChange(file) {     
      if (!file || file.size >= 2000000) {
        this.form.attachments = [];
        return;
      }             
      var reader = new FileReader();
      reader.readAsDataURL(file);      
      reader.onload = () => {                      
        this.form.attachments[0] = 
        {
          fileName: 'ZTP-' + file.name,
          base64Data: reader.result 
        };          
      };             
    }               
  },
};
</script>


