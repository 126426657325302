<template>
  <div class="px-sm-0 px-2">
    <v-checkbox v-model="agreement" :rules="[rules.required]" class="mt-1">
      <template v-slot:label>
        <div>
          {{ $t("checkout.agreementText") }}
          <a
            target="_blank"
            @click.stop
            :href="$t('checkout.agreementLinkUrl')"
            >{{ $t("checkout.agreementLinkText") }}</a
          >
        </div>
      </template>
    </v-checkbox>

    <v-checkbox v-model="gdpr" :rules="[rules.required]" class="mt-1">
      <template v-slot:label>
        <div>
          {{ $t("checkout.privacyPolicyAgreementText") }}
          <a
            target="_blank"
            @click.stop
            :href="$t('checkout.privacyPolicyAgreementLinkUrl')"
            >{{ $t("checkout.privacyPolicyAgreementLinkText") }}</a
          >
        </div>
      </template>
    </v-checkbox>

    <v-btn
      class="mt-2"
      :disabled="!valid"
      block
      :loading="loading"
      color="primary"
      depressed
      tile
      large
      @click="submit($event)"
      >{{ $t("checkout.placeOrder") }}</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "PlaceOrder",
  props: ["form", "loading", "valid"],
  data() {
    return {
      rules: window.validationRules.getRules(),
      gdpr: false,
      agreement: false,
    };
  },
  methods: {
    submit(event) {
      this.$emit("submit", event);
    },
  },
};
</script>
