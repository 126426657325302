<template>
  <v-stepper class="elevation-0" alt-labels v-model="stepNumber">
    <v-stepper-header>
      <v-stepper-step step="1" :complete="stepNumber > 1">{{
        $t("mainStepper.step_01")
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="2" :complete="stepNumber > 2">{{
        $t("mainStepper.step_02")
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="3" :complete="stepNumber > 3">{{
        $t("mainStepper.step_03")
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="4" :complete="stepNumber > 4">{{
        $t("mainStepper.step_04")
      }}</v-stepper-step>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainStepper",
  computed: {
    ...mapGetters(["stepNumber"]),
  },
};
</script>
    